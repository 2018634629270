import React from 'react';
import './css/About.css';
import FetchAndRenderMarkdown from '../components/FetchAndRenderMarkdown';
import Section from '../components/Section';
import useScrollToAnchor from '../components/useScrollToAnchor'

function About() {

  useScrollToAnchor();
  
  return (
    <div className="About">
      <FetchAndRenderMarkdown 
        url='/aboutMe.md' 
        images={[{ images: ['/IMG_1743.jpg'], imagePosition: 'right'}]}
        Component={Section}
        id='about-me'
      />
    </div>
  );
}

export default About;