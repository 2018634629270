// src/components/Footer.js (modified)
import React from 'react';
import { Link } from 'react-router-dom'; // import the Link component
import './css/footer.css'

function Footer() {
    return (
        <div id="Footer" className="Footer centerText center">
            <div className='footer-links'>
                <Link to="/PrivacyPolicy">Privacy Policy</Link>
                <Link to="/TermsAndConditions">Terms & Conditions</Link>
                <Link to="/PurchaseInformation">Purchase Information</Link>
                <Link to="/ClientExperience">Client Experience Process</Link>
                <Link to="/BusinessPolicies">Business Policies</Link>
                <Link to="/Contact">Contact Me</Link>
            </div>
            © 2024 Flourish Brand Photography
        </div>
    );
}

export default Footer;
