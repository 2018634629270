import React, { useState } from 'react';
import './css/Work.css';
import useScrollToAnchor from '../components/useScrollToAnchor'
import ImageModal from '../components/ImageViewer';

function Work() {
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    useScrollToAnchor();
    
    const handleOpenModal = (image) => {
        setShowModal(true);
        setSelectedImage(image);
    };
    
    return (
        <div className="Work center">
            {showModal && (
                <ImageModal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                image={selectedImage}
                />
            )}
            <div className="Section1">
                <img src='/images/Portraits/DSCF4262-lowres.jpg' alt='portrait' onClick={() => handleOpenModal('/images/Portraits/DSCF4262.jpg')} />
                <img src='/images/Portraits/DSCF4266-lowres.jpg' alt='portrait' onClick={() => handleOpenModal('/images/Portraits/DSCF4266.jpg')} />
                <img src='/images/Portraits/DSCF4257-lowres.jpg' alt='portrait' onClick={() => handleOpenModal('/images/Portraits/DSCF4257.jpg')} />
            </div>
            <div className="Section2">
                <img src='/images/Portraits/DSCF4302-lowres.jpg' alt='portrait' onClick={() => handleOpenModal('/images/Portraits/DSCF4302.jpg')} />
                <img src='/images/Portraits/DSCF4292-lowres.jpg' alt='portrait' onClick={() => handleOpenModal('/images/Portraits/DSCF4292.jpg')} />
                <img src='/images/Portraits/DSCF4298-lowres.jpg' alt='portrait' onClick={() => handleOpenModal('/images/Portraits/DSCF4298.jpg')} />
                <img src='/images/Portraits/DSCF4289-lowres.jpg' alt='portrait' onClick={() => handleOpenModal('/images/Portraits/DSCF4289.jpg')} />
            </div>
            <div className="Section2">
                <img src="/images/Portraits/DSCF4324-lowres.jpg" alt='portrait' onClick={() => handleOpenModal('/images/Portraits/DSCF4324.jpg')} />
                <img src='/images/Portraits/DSCF4315-lowres.jpg' alt='portrait' onClick={() => handleOpenModal('/images/Portraits/DSCF4315.jpg')} />
                <img src='/images/Portraits/DSCF4324-lowres.jpg' alt='portrait' onClick={() => handleOpenModal('/images/Portraits/DSCF4324.jpg')} />
                <img src='/images/Portraits/DSCF4326-lowres.jpg' alt='portrait' onClick={() => handleOpenModal('/images/Portraits/DSCF4326.jpg')} />
            </div>  
            <div className="Section2">
                <img src="/images/Portraits/DSCF2738-lowres.jpg" alt='portrait' onClick={() => handleOpenModal('/images/Portraits/DSCF2738.jpg')} />
                <img src='/images/Portraits/DSCF2793-lowres.jpg' alt='portrait' onClick={() => handleOpenModal('/images/Portraits/DSCF2793.jpg')} />
                <img src='/images/Portraits/DSCF2780-lowres.jpg' alt='portrait' onClick={() => handleOpenModal('/images/Portraits/DSCF2780.jpg')} />
                <img src='/images/Portraits/DSCF2850-lowres.jpg' alt='portrait' onClick={() => handleOpenModal('/images/Portraits/DSCF2850.jpg')} />
            </div>  
            <div className="Section2">
                <img src="/images/Portraits/AEC9A958-6336-4C04-99D8-25E24975B363_1_105_c-lowres.jpg" alt='portrait' onClick={() => handleOpenModal('/images/Portraits/AEC9A958-6336-4C04-99D8-25E24975B363_1_105_c.jpg')} />
                <img src='/images/Portraits/8252F549-B346-4792-B015-2771B0EE455D_1_105_c-lowres.jpg' alt='portrait' onClick={() => handleOpenModal('/images/Portraits/8252F549-B346-4792-B015-2771B0EE455D_1_105_c.jpg')} />
                <img src="/images/Portraits/DSCF1224-lowres.jpg" alt='portrait' onClick={() => handleOpenModal('/images/Portraits/DSCF1224.jpg')} />
                <img src='/images/Portraits/DSCF1225-lowres.jpg' alt='portrait' onClick={() => handleOpenModal('/images/Portraits/DSCF1225.jpg')} />
            </div>
        </div>
    );
}

export default Work;