import React from 'react';
import './css/ConsultationForm.css';
import useScrollToAnchor from '../../components/useScrollToAnchor'

function ConsultationForm(
    {
        lastName,
        firstName,
        emailAddress,
        phoneNumber,
        preferredContactMethod,
        selectedServices,
        clientCommitment1,
        clientCommitment2,
        clientCommitment3,
        clientCommitment4,
        clientCommitment5,
        clientCommitment6,
        clientCommitment7,
        clientCommitment8,
        onChangeFirstName,
        onChangeLastName,
        onChangeEmailAddress,
        onChangePhoneNumber,
        onChangePreferredContactMethod,
        onChangeSelectedServices,
        onChangeClientCommitment1,
        onChangeclientCommitment2,
        onChangeclientCommitment3,
        onChangeclientCommitment4,
        onChangeclientCommitment5,
        onChangeclientCommitment6,
        onChangeclientCommitment7,
        onChangeclientCommitment8,
        handleSubmit
        // Rest of the props...
    }) {

    useScrollToAnchor();

    return (
        <div className="ConsultationForm center">
            <h2>Consultation Form</h2>
            <form onSubmit={handleSubmit}>
                {/* Row 1: First and Last Name */}
                <div className="row">
                    <div>
                        <label>First Name:</label>
                        <input type="text" value={firstName} onChange={onChangeFirstName} />
                    </div>
                    <div>
                        <label>Last Name:</label>
                        <input type="text" value={lastName} onChange={onChangeLastName} />
                    </div>
                </div>


                {/* Row 2: Email Address */}
                <div className="row section">
                <label>Email Address:</label>
                <input type="email" value={emailAddress} onChange={onChangeEmailAddress} />
                </div>

                {/* Row 3: Phone Number */}
                <div className="row section">
                <label>Phone Number:</label>
                <input type="tel" value={phoneNumber} onChange={onChangePhoneNumber} />
                </div>

                {/* Row 4: Preferred Contact Method */}
                <div className="row section">
                <label>How can we reach you?</label>
                <select value={preferredContactMethod} onChange={onChangePreferredContactMethod}>
                    <option value="">Select an option</option>
                    <option value="email">Email</option>
                    <option value="phone">Phone Call</option>
                    <option value="text">Text Message</option>
                </select>
                </div>

                {/* Row 5: Selected Services and Packages */}
                <div className="packages section">
                <label>Which service(s) and package(s) are you interested in?</label>
                <ul>
                    <li>
                    <input
                        type="checkbox"
                        name="service1"
                        checked={selectedServices.service1}
                        onChange={onChangeSelectedServices}
                    />
                    <span>Brand Photography</span>
                    </li>
                    <li>
                    <input
                        type="checkbox"
                        name="service2"
                        checked={selectedServices.service2}
                        onChange={onChangeSelectedServices}
                    />
                    <span>Content Creation</span>
                    </li>
                    <li>
                    <input
                        type="checkbox"
                        name="service3"
                        checked={selectedServices.service3}
                        onChange={onChangeSelectedServices}
                    />
                    <span>Offerings & Launches</span>
                    </li>
                    <li>
                    <input
                        type="checkbox"
                        name="service4"
                        checked={selectedServices.service4}
                        onChange={onChangeSelectedServices}
                    />
                    <span>Event Photography</span>
                    </li>
                    <li>
                    <input
                        type="checkbox"
                        name="service5"
                        checked={selectedServices.service5}
                        onChange={onChangeSelectedServices}
                    />
                    <span>Customized Photography</span>
                    </li>
                    {/* Add more services and packages as needed */}
                </ul>
                </div>

                {/* Client Commitment Section */}
                <h2 className='center'>Client Commitment</h2>
                <p>
                    <strong>Brand photography involves a comprehensive process designed to deliver an exceptional client experience.</strong> This includes in-depth research to customize the shoot to your brand, a pre-shoot strategy meeting, securing an appropriate studio, and potentially assisting with booking a makeup artist or stylist. To ensure a successful collaboration, it’s crucial that clients are fully committed to the process, including timely feedback, adherence to schedules, and active participation.
                    With all of that said, I’m here to make your photography experience as enjoyable and stress-free as possible! I’m excited to collaborate with you and create stunning images that capture the essence of your brand. Let's work together to make this a fantastic and memorable experience. Ready to get started? Let’s make some magic happen!
                </p>
                <div className='section'>
                    <label>You are prepared to be actively involved in the planning and creative process to ensure the final images align with your vision.</label>
                    <select value={clientCommitment1} onChange={onChangeClientCommitment1}>
                        <option value="">Select an option</option>
                        <option value="agree">agree</option>
                        <option value="disagree">disagree</option>
                    </select>
                </div>

                <div className='section'>
                    <label>You agree that you don’t foresee any potential issues that could lead to cancellation or rescheduling.</label>
                    <select value={clientCommitment2} onChange={onChangeclientCommitment2}>
                        <option value="">Select an option</option>
                        <option value="agree">agree</option>
                        <option value="disagree">disagree</option>
                    </select>
                </div>

                <div className='section'>
                    <label>You agree to be open to creative suggestions and adjustments during the shoot, and to receiving feedback and revisions in post-production.</label>
                    <select value={clientCommitment3} onChange={onChangeclientCommitment3}>
                        <option value="">Select an option</option>
                        <option value="agree">agree</option>
                        <option value="disagree">disagree</option>
                    </select>
                </div>

                <div className='section'>  
                    <label>Upon onboarding, you agree to carefully review and understand the agreed-upon payment schedules and contract terms without delay.</label>
                    <select value={clientCommitment4} onChange={onChangeclientCommitment4}>
                        <option value="">Select an option</option>
                        <option value="agree">agree</option>
                        <option value="disagree">disagree</option>
                    </select>
                </div>

                <div className='section'>
                    <label>You understand there are usage rights and restrictions for the final images.</label>
                    <select value={clientCommitment5} onChange={onChangeclientCommitment5}>
                        <option value="">Select an option</option>
                        <option value="agree">agree</option>
                        <option value="disagree">disagree</option>
                    </select>
                </div>

                <div className='section'>
                    <label>If applicable, you agree to sign any model and/or property releases.</label>
                    <select value={clientCommitment6} onChange={onChangeclientCommitment6}>
                        <option value="">Select an option</option>
                        <option value="agree">agree</option>
                        <option value="disagree">disagree</option>
                    </select>
                </div>

                <div className='section'>
                    <label>You understand your responsibilities as a client, such as providing access to locations, obtaining necessary permissions, and being available for consultations?</label>
                    <select value={clientCommitment7} onChange={onChangeclientCommitment7}>
                        <option value="">Select an option</option>
                        <option value="agree">agree</option>
                        <option value="disagree">disagree</option>
                    </select>
                </div>
                <div className='section'>
                    <label>By proceeding with my services, you acknowledge and agree that the photographer retains the copyright to all images produced.</label>
                    <select value={clientCommitment8} onChange={onChangeclientCommitment8}>
                        <option value="">Select an option</option>
                        <option value="agree">agree</option>
                        <option value="disagree">disagree</option>
                    </select>
                </div>
            </form>
        </div>
    );
}

export default ConsultationForm;