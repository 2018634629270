import React from 'react';
import './css/businesspolicies.css';
import FetchAndRenderMarkdown from '../../components/FetchAndRenderMarkdown';
import textblock from '../../components/textblock';
import useScrollToAnchor from '../../components/useScrollToAnchor'

function BusinessPolicies() {

  useScrollToAnchor();
  
  return (
    <div className="BusinessPolicies center">
        <FetchAndRenderMarkdown 
                url='/Pages/BusinessPolicies/BusinessPolicies.md' 
                Component={textblock}
                className='BusinessPolicies'
                id='BusinessPolicies'
            />
    </div>
  );
}

export default BusinessPolicies;