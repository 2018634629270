import React, { useState } from 'react';
import './css/stepsindicator.css'
const StepIndicator = ({ 
    steps, 
    currentStep,
    onChangeSetStep
}) => {

  const handleNextStep = () => {
    if (!steps[currentStep - 1].validation()) { // Call the validation function
        console.log("Validation failed!");
    } else {
        console.log("Confirmed!")
        if (currentStep < steps.length) {
            // Execute the callback function for the current step
            steps[currentStep - 1].onComplete();

            // Move to the next step
            onChangeSetStep(currentStep + 1);
        }
    }
  };


  return (
    <div className="StepIndicator center">
        {/* Indicator line */}
      <div className="indicator-line">
            {Array.from({ length: steps.length }, (_, i) => (
                <div key={i} className="step-container">
                <span className={`step-indicator ${currentStep === i + 1 ? 'active' : ''}`} />
                <span className="step-title">{steps[i].title}</span>
                </div>
            ))}
        </div>



        {/* Render active step content */}
        <div className="step-content">
            {steps.map((step, i) => (
                i + 1 === currentStep ? (
                    <div key={i}>{step.component}</div>
                ) : null
            ))}
        </div>

        { /* Next button */ }
        { !steps[currentStep - 1].lastStep && (
        steps[currentStep - 1].buttonValidation
            ? (
            <button className='custom-button section submit-enabled' onClick={handleNextStep}>Next</button>
            ) : (
            <button className='custom-button section submit-disabled' onClick={handleNextStep}>Next</button>
            )
        )}
    </div>
  );
};

export default StepIndicator;
