import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './css/ContactMe.css';
import useScrollToAnchor from '../components/useScrollToAnchor'

function ContactMe() {
    const navigate = useNavigate();
    const location = useLocation();
    const timeSlot = location.state?.event;
    const timeZone = location.state?.timeZone;

    useScrollToAnchor();

    // Initialize state for the form fields
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [message, setMessage] = useState('');
    const [contactType, setcontactType] = useState({
        Individual: false,
        Business: false
        // Add more services and packages as needed
    });

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = {
            firstName: firstName,
            lastName: lastName,
            emailAddress: emailAddress,
            contactType: contactType.Individual ? 'Individual' : contactType.Business ? 'Business' : '',
            message: message,
        };

        try {
            const response = await fetch('/api/ContactMe', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
            });

            if (response.ok) {
                navigate('/Success', { state: {
                    successMessage: `Hi ${firstName}, thanks for reaching out! I will be in touch within 48 hours to discuss your inquiry and see how we can assist you.`,
                } });
            } else {
                console.error('Error submitting form:', response.statusText);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };



    return (
        <div className="ContactMe center">
            <h2>Contact Me</h2>
            <form onSubmit={handleSubmit}>
                {/* Row 1: First and Last Name */}
                <div className="row section2">
                    <div>
                        <label>First Name:</label>
                        <input type="text" value={firstName} onChange={(event) => setFirstName(event.target.value)} />
                    </div>
                    <div>
                        <label>Last Name:</label>
                        <input type="text" value={lastName} onChange={(event) => setLastName(event.target.value)} />
                    </div>
                </div>

                {/* Row 3: Email Address */}
                <div className="EmailSection row section2">
                    <label>Email Address:</label>
                    <div className="input-container">
                        <input type="email" value={emailAddress} onChange={(event) => setEmailAddress(event.target.value)} />
                    </div>
                </div>

                {/* Row 4: Individual or Business */}
                <div className="contactType section section2">
                    <label>Are you an individual or business?</label>
                    <ul>
                        <li>
                            <input
                                type="checkbox"
                                checked={contactType.Individual}
                                onChange={() => setcontactType({ ...contactType, Individual: !contactType.Individual })}
                            />
                            <span>Individual</span>
                        </li>
                        <li>
                            <input
                                type="checkbox"
                                checked={contactType.Business}
                                onChange={() => setcontactType({ ...contactType, Business: !contactType.Business })}
                            />
                            <span>Business</span>
                        </li>
                    </ul>
                </div>

                {/* Row 5: Message */}
                <div className="Message section2">
                    <label>Message:</label>
                    <div className="input-container">
                        <textarea
                            rows={5}
                            value={message}
                            onChange={(event) => setMessage(event.target.value)}
                        />
                    </div>
                </div>

                {/* Submit button */}
                <div className='center'>
                    {
                        Object.values(contactType).some((value) => value) &&
                        firstName && lastName && emailAddress && message
                        ? (
                            <button className='custom-button section submit-enabled' type="submit" onClick={handleSubmit}>Submit Form</button>
                        ) : (
                            <button className='custom-button section submit-disabled' type="submit" disabled>Submit Form</button>
                        )
                    }
                </div>
            </form>
        </div>
    );
}

export default ContactMe;