import './css/minicollage.css';

function MiniCollage({ images }) {
    return (
        <div className="mini-collage">
            {images.map((image, index) => (
                <img key={index} src={image} alt={`collage-${index}`} />
            ))}
        </div>
    );
}

export default MiniCollage;