import React from 'react';
import './css/Home.css';
import FetchAndRenderMarkdown from '../components/FetchAndRenderMarkdown';
import textblock from '../components/textblock';
import Section from '../components/Section';
import MiniCollage from '../components/MiniCollage';
import useScrollToAnchor from '../components/useScrollToAnchor'
import MeshImgText from '../components/MeshImgText';

function Home() {

    useScrollToAnchor();

    return (
        <div className="Welcome center">
            <div className="Section1 center"> 
                <FetchAndRenderMarkdown
                    url='/Pages/Welcome/BrandPhotography.md' 
                    Component={textblock}
                    className='SectionText center centerText'
                    id='BrandPhotography'
                />
            </div>
            <div className="images1">
                    <img src='/images/Portraits/DSCF4262-lowres.jpg' alt='portrait' />
                    <img src='/images/Portraits/DSCF4326-lowres.jpg' alt='portrait' />
                    <img src='/images/Portraits/DSCF4292-lowres.jpg' alt='portrait' />
            </div>
            <FetchAndRenderMarkdown 
                url='/Pages/Welcome/Section2.md' 
                Component={textblock}
                className='Section2 center centerText'
                id='BrandExtended'
            />
            <div className="images1">
                    <img src='/images/Portraits/DSCF2738-lowres.jpg' alt='portrait' />
                    <img src='/images/Portraits/DSCF2780-lowres.jpg' alt='portrait' />
            </div>
            <FetchAndRenderMarkdown 
                url='/Pages/Welcome/Services.md' 
                Component={textblock}
                className='Section4 centerText'
                id='Services'
            />
            <div className='center'>
                <FetchAndRenderMarkdown 
                    url='/Pages/Welcome/BrandPhotographySum.md' 
                    image='/images/Portraits/DSCF4399-lowres.jpg'
                    Position='Left'
                    Component={MeshImgText}
                    className='Section5 center'
                    id='SamleText1'
                />  
                <FetchAndRenderMarkdown 
                    url='/Pages/Welcome/ContentCreationSum.md' 
                    image='/images/Portraits/dscf0555-lowres.jpg'
                    Position='Right'
                    Component={MeshImgText}
                    className='Section5 center'
                    id='SamleText1'
                />
                <FetchAndRenderMarkdown 
                    url='/Pages/Welcome/OfferingsLaunchesSum.md' 
                    image='/images/Portraits/EA484E8D-547D-49E9-80A4-28CF1C81572E-lowres.jpg'
                    Position='Left'
                    Component={MeshImgText}
                    className='Section5 center'
                    id='SamleText1'
                />
                <FetchAndRenderMarkdown 
                    url='/Pages/Welcome/EventPhotographySum.md' 
                    image='/images/Portraits/DSCF0958-lowres.jpg'
                    Position='Right'
                    Component={MeshImgText}
                    className='Section5 center'
                    id='SamleText1'
                />
            </div>
        </div>
    );
}

export default Home;