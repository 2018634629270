import React from 'react';
import './css/textblock.css';

function TextBlock({ id, text }) {
    return (
        <div {...(id ? { id: id } : {})} className="GradientBorderWrapper">
            <div className="TextBlock" dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    );
}

export default TextBlock;