import React, { createContext } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import pages from './pages/pagesConfig';
import Navigation from './components/Navigation';
import RoutePathProvider from './components/RoutePathProvider';
import Footer from './components/Footer';

const RoutePathContext = createContext('/');

function App() {  
  return (
    <Router>
      <RoutePathProvider RoutePathContext={RoutePathContext}>
        <div className="App">
          <div className='header'>
            <Navigation RoutePathContext={RoutePathContext} />
          </div>
          <div className='body'>
          <Routes>
            {pages.map((page, index) => (
              <Route key={index} path={page.path} element={
                <div className='body-section'>
                  <page.component />
                </div>
              } />
            ))}
          </Routes>
            <Footer/>
          </div>
        </div>
      </RoutePathProvider>
    </Router>
  );
}

export default App;