import Home from './Home';
import About from './About';
import Work from './Work';
import Investment from './Investment';
import Collaboration from './Collaboration';
import Consultation from './Consultation';
import ContactMe from './ContactMe';
import BusinessPolicies from './HiddenPages/BusinessPolicies';
import ClientExperience from './HiddenPages/ClientExperience';
import PurchaseInformation from './HiddenPages/PurchaseInformation';
import TermsConditions from './HiddenPages/TermsConditions';
import PrivacyPolicy from './HiddenPages/PrivacyPolicy';
import ConsultationForm from './HiddenPages/ConsolutationForm';
import SuccessPage from './HiddenPages/SuccessPage';
// import other pages here

export default [
  {
    path: '/',
    component: Home,
    name: 'Welcome',
    navBar: true
  },
  {
    path: '/About',
    component: About,
    name: 'About',
    navBar: true
  },
  {
    path: '/Work',
    component: Work,
    name: 'Work',
    navBar: true
  },
  {
    path: '/Investment',
    component: Investment,
    name: 'Investment',
    navBar: true
  },
  {
    path: '/Consultation',
    component: Consultation,
    name: 'Consultation',
    navBar: true
  },
  {
    path: '/Collaboration',
    component: Collaboration,
    name: 'Collaboration',
    navBar: true
  },
  {
    path: '/Contact',
    component: ContactMe,
    name: 'Contact Me',
    navBar: true
  },
  {
    path: '/BusinessPolicies',
    component: BusinessPolicies,
    name: 'BusinessPolicies',
    navBar: false
  },
  {
    path: '/PurchaseInformation',
    component: PurchaseInformation,
    name: 'PurchaseInformation',
    navBar: false
  },
  {
    path: '/ClientExperience',
    component: ClientExperience,
    name: 'ClientExperience',
    navBar: false
  },
  {
    path: '/TermsAndConditions',
    component: TermsConditions,
    name: 'Terms&Conditions',
    navBar: false
  },
  {
    path: '/PrivacyPolicy',
    component: PrivacyPolicy,
    name: 'PrivacyPolicy',
    navBar: false
  },
  {
    path: '/ConsultationForm',
    component: ConsultationForm,
    name: 'ConsultationForm',
    navBar: false
  },
  {
    path: '/Success',
    component: SuccessPage,
    name: 'SuccessPage',
    navBar: false
  },
  // add other pages here
];