import React from 'react';
import './css/termsconditions.css';
import FetchAndRenderMarkdown from '../../components/FetchAndRenderMarkdown';
import textblock from '../../components/textblock';
import useScrollToAnchor from '../../components/useScrollToAnchor'

function TermsConditions() {

  useScrollToAnchor();
  
  return (
    <div className="TermsConditions center">
        <FetchAndRenderMarkdown 
          url='/termsandconditions.md' 
          Component={textblock}
          id='Privacypolicy'
          className='center'
        />
    </div>
  );
}

export default TermsConditions;