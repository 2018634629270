import { useState, useEffect } from 'react';
import { marked } from 'marked';

function FetchAndRenderMarkdown({ url, Component, images= [], WrapperComponent = 'div', wrapperClassName, ContainerClass = 'div', containerClassName, componentClassName, breakBySection, ...props }) {
    const [content, setContent] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        fetch(url)
            .then(response => response.text())
            .then(markdown => {
                const html = marked(markdown);
                if (breakBySection) {
                    const sections = html.split(/(?=<h2>)/); // Splitting by <h2> tags
                    setContent(sections);
                } else {
                    setContent([html]);
                }
                setIsLoading(false);
            });
    }, [url, breakBySection]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <WrapperComponent className={wrapperClassName} {...props}>
            {content.map((text, index) => (
                <ContainerClass key={index} className={containerClassName}>
                    <Component className={componentClassName} {...props} text={text} images={images[index]?.images} imagePosition={images[index]?.imagePosition} />
                </ContainerClass>
            ))}
        </WrapperComponent>
    );
}

export default FetchAndRenderMarkdown;