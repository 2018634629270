import React from 'react';
import './css/privacypolicy.css';
import FetchAndRenderMarkdown from '../../components/FetchAndRenderMarkdown';
import textblock from '../../components/textblock';
import useScrollToAnchor from '../../components/useScrollToAnchor'

function PrivacyPolicy() {

  useScrollToAnchor();
  
  return (
    <div className="PrivacyPolicy center">
      <FetchAndRenderMarkdown 
        url='/privacypolicy.md' 
        Component={textblock}
        id='Privacypolicy'
        className='center'
      />
    </div>
  );
}

export default PrivacyPolicy;