import React from 'react';
import './css/nametitle.css';

const NameTitle = () => {
    return (
        <div className="name-title">
            <h1>Flourish Brand Photography</h1>
        </div>
    );
};

export default NameTitle;