import React from 'react';
import './css/PurchaseInformation.css';
import FetchAndRenderMarkdown from '../../components/FetchAndRenderMarkdown';
import textblock from '../../components/textblock';
import useScrollToAnchor from '../../components/useScrollToAnchor'

function PurchaseInformation() {

  useScrollToAnchor();
  
  return (
    <div className="PurchaseInformation">
        <FetchAndRenderMarkdown 
            url='/Pages/Investment/Information.md' 
            Component={textblock}
            className='Information'
            id='Information'
        />
        <FetchAndRenderMarkdown 
            url='/Pages/Investment/Discounts.md' 
            Component={textblock}
            className='DiscountsSection center centerText'
            id='Discounts'
        />
    </div>
  );
}

export default PurchaseInformation;