import './css/Packaging.css';

function Package(props) {
    return (
        <div className="Packaging">
            <h3 className='Package'>{props.Package}</h3>
            <p><span className="Price">${props.Price}</span> <span className="PriceNote">{props.PriceNote}</span></p>
            <div className='Includes'>
                <ul>
                    {props.Includes.map((include, index) => <li key={index}>{include}</li>)}
                </ul>
            </div>
        </div>
    );
}

export default Package;