// Navigation.js
import React, { useContext, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import pages from '../pages/pagesConfig';
import HeaderContent from './HeaderContent';
import './css/navigation.css';

function Navigation( { RoutePathContext } ) {
    const [collapsed, setCollapsed] = useState(true);
    const currentPath = useContext(RoutePathContext);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    };

    const handleLinkClick = () => {
        setCollapsed(true);
    };

    console.log('currentPath:', currentPath);
    return (
        <nav className={`navbar`}>
            <div className='headerContent'>
                <HeaderContent className='headerContent'/>
            </div>
            <div className='navLinks'>
                <FaBars className={`navbar-toggle ${collapsed ? 'collapsed' : 'show'}`} onClick={toggleNavbar} /> {/* Hamburger icon */}
                <ul className={collapsed ? '' : 'collapsed'}>
                    {pages.filter(page => page.navBar).map((page, index) => (
                        <li key={index}>
                            <NavLink to={page.path} className={currentPath === page.path ? 'active-link' : ''} onClick={handleLinkClick}>{page.name}</NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </nav>
    );
}

export default Navigation;