import React from 'react';
import './css/Collaboration.css';
import FetchAndRenderMarkdown from '../components/FetchAndRenderMarkdown';
import TextBlock from '../components/textblock';

function Collaboration() {
    return (
        <div className="Collaboration">
            <FetchAndRenderMarkdown 
                url='/Pages/Collaboration/LetsCollaborate.md' 
                Component={TextBlock}
                className='Section3 centerText center'
                id='LetsCollaborate'
            />
            <FetchAndRenderMarkdown 
                url='/Pages/Collaboration/specialNote.md' 
                Component={TextBlock}
                className='specialNote centerText center'
            />
            <FetchAndRenderMarkdown 
                url='/Pages/Collaboration/Sessions.md' 
                Component={TextBlock}
                className='Sessions centerText center Border'
                id='Sessions'
            />
            <FetchAndRenderMarkdown 
                url='/Pages/Collaboration/Responsibilities.md' 
                Component={TextBlock}
                className='Responsibilities centerText center Border'
                id='Responsibilities'
            />
            <FetchAndRenderMarkdown 
                url='/Pages/Collaboration/Perks.md' 
                Component={TextBlock}
                className='Perks centerText center Border'
                id='Perks'
            />
        </div>
    );
}

export default Collaboration;