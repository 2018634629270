// RoutePathProvider.js
import React from 'react';
import { useLocation } from 'react-router-dom';

function RoutePathProvider({ children, RoutePathContext }) {
    const location = useLocation();
    const currentPath = location.pathname;

    return (
        <RoutePathContext.Provider value={currentPath}>
            {children}
        </RoutePathContext.Provider>
    );
}

export default RoutePathProvider;