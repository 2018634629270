import './css/MeshImgText.css';
import TextBlock from './textblock';

function MeshImgText({ image, text, Position }) {
    return (
        <div className={"MeshImgText " + Position}>
            <div className="image-container">
                <img src={image} />
            </div>
            <div className="text-container">
                <TextBlock text={text} />
            </div>
        </div>
    );
}
export default MeshImgText;