// HeaderContent.js
import React from 'react';
import NameTitle from './nametitle';
import './css/headercontent.css';
import logo from '../assets/FlourishLogo.png'

function HeaderContent() {
    return (
        <div className={`Content`}>
            <img 
                src={logo} alt="Logo" 
                className="Logo" 
                onClick={() => window.location.href = '/'} 
            />
        </div>
    );
}

export default HeaderContent;