import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './css/successPage.css';
import useScrollToAnchor from '../../components/useScrollToAnchor'

function SuccessPage({Message}) {
    const location = useLocation();
    const navigate = useNavigate();

    const successMessage = Message ||location.state?.successMessage || 'Your request has been submitted.';

    useScrollToAnchor();
  
    return (
        <div className="SuccessPage center">
            <div className="success-page">
                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
                <h1 className="success-message">Success!</h1>
                <p className="success-message">{successMessage}</p>
                <button className="ok-button" onClick={() => navigate('/')}>OK</button>
            </div>
        </div>
    );
}

export default SuccessPage;