import React from 'react';
import './css/clientexperience.css';
import FetchAndRenderMarkdown from '../../components/FetchAndRenderMarkdown';
import textblock from '../../components/textblock';
import useScrollToAnchor from '../../components/useScrollToAnchor'

function ClientExperience() {

  useScrollToAnchor();
  
  return (
    <div className="ClientExperience">
        <FetchAndRenderMarkdown 
            url='/Pages/Investment/ClientExperience.md' 
            Component={textblock}
            className='ClientExperience center centerText'
            id='ClientExperience'
        />
    </div>
  );
}

export default ClientExperience;