import TextBlock from './textblock';
import './css/section.css';

function Section2({ id, images, title, text, imagePosition }) {
    const imageUrls = images || [];

    return (
        <div {...(id ? { id: id } : {})} className={`section ${imagePosition}`}>
            <div className="image-container">
                {imageUrls.map((image, index) => (
                    <img key={index} src={image} alt={`${title}-${index}`} />
                ))}
            </div>
            <TextBlock title={title} text={text} />
        </div>
    );
}

export default Section2;