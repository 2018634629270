import React from 'react';
import Modal from 'react-modal';

const ImageModal = ({ isOpen, onClose, image }) => {
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Larger Image"
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.0)'
        },
        content: {
          inset: '1rem',
          backgroundColor: 'rgba(48, 47, 47,.9)'
        }
      }}
    >
        {/* Close button at top-right corner with an X icon */}
        <button
            onClick={onClose}
            style={{
                position: 'absolute',
                right: 0,
                top: 0,
                margin: '8px', // equivalent to Tailwind's m-4
                padding: '2px', // equivalent to Tailwind's p-2
                backgroundColor: '#302f2f', // Tailwind's bg-red-200
                hover: {
                  backgroundColor: 'rgba(255, 0, 0, 0.3)',
                },
                color: 'white',
                borderRadius: 'md', // assuming you have a md radius defined somewhere
                outline: 'none',
              }}
        >
            &#10006;
        </button>
        <div className='modalimg center'>
            {/* The image */}
            <img src={image} alt="larger image" style={{
              maxHeight: '91vh',
              '@media (min-width: 1000px)': {
                maxHeight: 'auto',
                maxWidth: '60vw'
              }
            }}/>
        </div>
    </Modal>
  );
};

export default ImageModal;