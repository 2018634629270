import React, { useState, useEffect } from 'react';
import './css/Consultation.css';
import FetchAndRenderMarkdown from '../components/FetchAndRenderMarkdown';
import TextBlock from '../components/textblock';
import useScrollToAnchor from '../components/useScrollToAnchor'
import StepsIndicator from '../components/StepsIndicator';
import ConsultationForm from '../pages/HiddenPages/ConsolutationForm';
import SuccessPage from '../pages/HiddenPages/SuccessPage';

function Consultation() {
    // Initialize state for the form fields
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [preferredContactMethod, setPreferredContactMethod] = useState('');
    const [selectedServices, setSelectedServices] = useState({
        service1: false,
        service2: false,
        service3: false,
        service4: false,
        service5: false,
        // Add more services and packages as needed
    });
    const [clientCommitment1, setClientCommitment1] = useState('');
    const [clientCommitment2, setclientCommitment2] = useState('');
    const [clientCommitment3, setclientCommitment3] = useState('');
    const [clientCommitment4, setclientCommitment4] = useState('');
    const [clientCommitment5, setclientCommitment5] = useState('');
    const [clientCommitment6, setclientCommitment6] = useState('');
    const [clientCommitment7, setclientCommitment7] = useState('');
    const [clientCommitment8, setclientCommitment8] = useState('');
    const [isFormFilledOut, setIsFormFilledOut] = useState(false);
    const [step, setStep] = useState(1);

    useEffect(() => {
        if (validateFormData()) {
            setIsFormFilledOut(true);
        } else {
            setIsFormFilledOut(false);
        }
        console.log("is form filled out: " + isFormFilledOut)
    }, [
        clientCommitment1,
        clientCommitment2,
        clientCommitment3,
        clientCommitment4,
        clientCommitment5,
        clientCommitment6,
        clientCommitment7,
        clientCommitment8,

        firstName,
        lastName,
        emailAddress,
        phoneNumber,
        preferredContactMethod,
        selectedServices
    ]);

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    const validatePhoneNumber = (phone) => {
        const phoneRegex = /^(\+?\d{1,3})?[-. ]?(\(?(\d{3})\)?)[- .]?(\d{3})[- .]?(\d{4})$/;
        return phoneRegex.test(phone);
    };

    const sanitizeInput = (input) => {
        const sanitizedInput = input.replace(/[^a-zA-Z0-9\s]/g, '');
        return sanitizedInput;
    };

    const validateFormData = () => {
        const clientCommitmentsAgreed = [
            clientCommitment1,
            clientCommitment2,
            clientCommitment3,
            clientCommitment4,
            clientCommitment5,
            clientCommitment6,
            clientCommitment7,
            clientCommitment8,
        ].every((commitment) => commitment === "agree");

        const contactInfoProvided = [
            sanitizeInput(firstName),
            sanitizeInput(lastName),
            emailAddress,
            phoneNumber,
            preferredContactMethod,
        ];

        return (
            Object.values(selectedServices).some((value) => value) &&
            clientCommitmentsAgreed &&
            contactInfoProvided.every((info) => info !== "") &&
            validateEmail(emailAddress) &&
            validatePhoneNumber(phoneNumber)
        );
    };

    const handleFormSubmit = async (event) => {
        const servicesMap = {
            service1: 'Brand Photography',
            service2: 'Content Creation',
            service3: 'Offerings & Launches',
            service4: 'Event Photograpy',
            service5: 'Customized Photography',
          };
        
          const selectedServicesArray = Object.keys(selectedServices)
            .filter((key) => selectedServices[key])
            .map((key) => servicesMap[key]);
        
          const selectedServicesString = `[${selectedServicesArray.join(', ')}]`;
        
          const formData = {
            firstName: firstName,
            lastName: lastName,
            emailAddress: emailAddress,
            phoneNumber: phoneNumber,
            preferredContactMethod: preferredContactMethod,
            selectedServices: selectedServicesString,
            clientCommitment1: clientCommitment1,
            clientCommitment2: clientCommitment2,
            clientCommitment3: clientCommitment3,
            clientCommitment4: clientCommitment4,
            clientCommitment5: clientCommitment5,
            clientCommitment6: clientCommitment6,
            clientCommitment7: clientCommitment7,
            clientCommitment8: clientCommitment8,
          };
        
        try {
            const response = await fetch('/api/Consultation', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
            });
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    useScrollToAnchor();
    
    return (
        <div className="Consultation center">
            <FetchAndRenderMarkdown 
                url='/Pages/Consultation/Consultation.md' 
                Component={TextBlock}
                className='Section1 centerText center'
                id='ComplimentaryConsultation'
            />
            <StepsIndicator
                currentStep={step}
                onChangeSetStep={(newStep) => setStep(newStep)}
                steps={[
                    {
                        component: <ConsultationForm
                            clientCommitment1={clientCommitment1}
                            onChangeClientCommitment1={(event) => setClientCommitment1(event.target.value)}
                            clientCommitment2={clientCommitment2}
                            onChangeclientCommitment2={(event) => setclientCommitment2(event.target.value)}
                            clientCommitment3={clientCommitment3}
                            onChangeclientCommitment3={(event) => setclientCommitment3(event.target.value)}
                            clientCommitment4={clientCommitment4}
                            onChangeclientCommitment4={(event) => setclientCommitment4(event.target.value)}
                            clientCommitment5={clientCommitment5}
                            onChangeclientCommitment5={(event) => setclientCommitment5(event.target.value)}
                            clientCommitment6={clientCommitment6}
                            onChangeclientCommitment6={(event) => setclientCommitment6(event.target.value)}
                            clientCommitment7={clientCommitment7}
                            onChangeclientCommitment7={(event) => setclientCommitment7(event.target.value)}
                            clientCommitment8={clientCommitment8}
                            onChangeclientCommitment8={(event) => setclientCommitment8(event.target.value)}
                            firstName={firstName}
                            onChangeFirstName={(event) => setFirstName(event.target.value)}
                            lastName={lastName}
                            onChangeLastName={(event) => setLastName(event.target.value)}
                            emailAddress={emailAddress}
                            onChangeEmailAddress={(event) => setEmailAddress(event.target.value)}
                            phoneNumber={phoneNumber}
                            onChangePhoneNumber={(event) => setPhoneNumber(event.target.value)}
                            preferredContactMethod={preferredContactMethod}
                            onChangePreferredContactMethod={(event) => setPreferredContactMethod(event.target.value)}
                            selectedServices={selectedServices}
                            onChangeSelectedServices={(event) => {
                                const { name } = event.target;
                                setSelectedServices({ ...selectedServices, [name]: !selectedServices[name] });
                            }}
                        />,
                        onComplete: () => handleFormSubmit(),
                        title: 'Step 1: Complete Form',
                        validation: () => validateFormData(),
                        buttonValidation: isFormFilledOut
                    },
                    {
                        component: <iframe className='google-cal' src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ053sHI_RgAAzQofFz2YkEC52rbbhNhMA-jlWeXivBHObyRKipZ3xKB3W2-1_cebN25WAX1Fie4?gv=true"></iframe>,
                        onComplete: () => console.log('Step 2 completed'),
                        title: 'Step 2: Book Appointment',
                        validation: () => true,
                        buttonValidation: () => true
                    },
                    {
                        component: <SuccessPage Message={`Thank you considering Flourish Brand Photography, ${firstName}. I'm excited to collaborate with you and capture your brand's essence. Looking forward to discussing further.`}/>,
                        onComplete: () => console.log('Step 3 completed'),
                        title: 'Step 3: Complete',
                        lastStep: true
                    }       
                ]}
            />
        </div>
    );
}

export default Consultation;